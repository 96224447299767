/** @jsx jsx */
import React from 'react';
import { jsx, useThemeUI } from 'theme-ui';

type Props = {
  children: React.ReactNode;
  className: string;
  href: string;
};

const MDXLink = ({ children, href, className }: Props) => {
  const { theme } = useThemeUI();
  return (
    <a
      className={className}
      sx={{ ...theme['links'].underlined }}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default MDXLink;
