/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import kebabCase from 'lodash.kebabcase';
import { ClassNameProp } from '../utils/types';
import HeadingAnchor from './anchor.inline.svg';
import VisuallyHidden from './visually-hidden';

type Props = ClassNameProp & {
  tag: keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
  children: React.ReactNode;
  [key: string]: any;
};

const MDXHeading = ({ tag: Tag, children, ...props }: Props) => {
  const id: string = kebabCase(children as string);

  return (
    <Tag
      id={id}
      {...props}
      sx={{ display: 'flex', alignItems: 'stretch', '&:hover': { a: { opacity: '1' } } }}
    >
      <a
        href={`#${id}`}
        sx={{
          display: ['none', 'flex'],
          alignItems: 'center',
          color: 'inherit',
          marginLeft: '-22px',
          marginRight: '6px',
          backfaceVisibility: 'hidden',
          opacity: '0',
          '@media (hover: none)': { opacity: '1' },
          transition: 'opacity 250ms ease 0s'
        }}
      >
        <HeadingAnchor width={16} height={16} />
        <VisuallyHidden>Link to this heading</VisuallyHidden>
      </a>
      <span>{children}</span>
    </Tag>
  );
};

export default MDXHeading;
