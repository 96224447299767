/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ClassNameProp } from '../utils/types';

type Props = ClassNameProp & {
  tag?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  [key: string]: any;
};

const VisuallyHidden = ({ className, tag: Tag = 'div', children, ...props }: Props) => (
  <Tag
    className={className}
    sx={{
      position: `absolute`,
      overflow: `hidden`,
      clip: `react(0 0 0 0)`,
      height: `1px`,
      width: `1px`,
      margin: `-1px`,
      padding: 0,
      border: 0
    }}
    {...props}
  >
    {children}
  </Tag>
);

export default VisuallyHidden;
