import React from 'react';
import { preToCodeBlock } from 'mdx-utils';
import { Text } from '@theme-ui/components';
import Code from '../components/code';
import Title from '../components/title';
import MDXLink from '../components/mdx-link';
import MDXHeading from '../components/mdx-heading';

const heading = (tag) => (props) => <MDXHeading tag={tag} {...props} />;

const components = {
  Text: ({ children, ...props }) => <Text {...props}>{children}</Text>,
  Title: ({ children, text, ...props }) => (
    <Title text={text} {...props}>
      {children}
    </Title>
  ),
  pre: (preProps) => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  a: (props) => <MDXLink {...props} />,
  wrapper: ({ children }) => <>{children}</>,
  h1: heading('h1'),
  h2: heading('h2'),
  h3: heading('h3'),
  h4: heading('h4'),
  h5: heading('h5'),
  h6: heading('h6')
};

export default components;
